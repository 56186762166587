.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #BE001E;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 11px 20px 9px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.button:hover,
.button:focus {
  color: #fff !important;
  background-color: #cf203c;
}
.desk {
  max-width: 100%;
}
.cb-home {
  margin-left: 40px;
}
.whitehome {
  left: 40px;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main .cb-album .body,
.cb-page-layout2 .main .head,
.cb-page-layout2 .main .foot,
.cb-page-layout2 .main .part {
  margin-right: 1.54004107%;
  margin-left: 1.54004107%;
  width: 96.91991786%;
}
.cb-page-layout2 .main .tiny {
  width: 46.91991786%;
}
.cb-page-layout2 .main > .slim {
  width: 50%;
}
.cb-page-layout2 .main > .slim .head,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout2 .main > .slim .part {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.cb-page-layout2 .main > .slim.cb-album .body {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.cb-page-layout2 .main > .slim .tiny {
  width: 43.83983573%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest .cb-album .body,
.farwest .head,
.farwest .foot,
.farwest .part {
  margin-right: 1.54004107%;
  margin-left: 1.54004107%;
  width: 96.91991786%;
}
.farwest .tiny {
  width: 21.91991786%;
}
.farwest > .slim {
  width: 25%;
}
.farwest > .slim .head,
.farwest > .slim .foot,
.farwest > .slim .part {
  margin-right: 6.16016427%;
  margin-left: 6.16016427%;
  width: 87.67967146%;
}
.farwest > .slim.cb-album .body {
  margin-right: 6.16016427%;
  margin-left: 6.16016427%;
  width: 87.67967146%;
}
.farwest > .slim .tiny {
  width: 87.67967146%;
}
.farwest {
  width: 103.1779661%;
  margin-left: -1.58898305%;
}
.cb-page-layout2 .main {
  width: 103.1779661%;
  margin-left: -1.58898305%;
}
/*# sourceMappingURL=./screen-large.css.map */